<template>
    <v-container>
      <h1>El poder de los Arcanos: sabiduría ancestral</h1>
      <h2>{{ fechaHoy }}</h2>
      <v-row>
        <v-col
          v-for="signo in signos"
          :key="signo.nombre"
          cols="4"
          md="3"
          lg="2"
        >
          <v-card
            class="mx-auto my-2"
            max-width="160"
            outlined
            hover
            @click="verHoroscopo(signo.nombre)"
          >
            <v-img
              :src="require(`@/assets/signos/${signo.imagen}`)"
              height="35px"
              contain
            ></v-img>
            <v-card-title class="title">{{ signo.nombre }}</v-card-title>
            <v-card-subtitle class="subtitle">{{ signo.fechas }}</v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
  
      <!-- Sección de chat con el oráculo -->
      <div class="chat-container">
        <v-card class="pa-3">
          <v-card-title>Conversa con el Oráculo</v-card-title>
          <v-card-text>
            <div class="messages">
              <div
                v-for="(message, index) in messages"
                :key="index"
                class="message"
                :class="{ 'is-user': message.isUser }"
              >
                <span class="chip">{{ message.text }}</span>
              </div>
            </div>
            <v-progress-linear
              v-if="loading"
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-text>
          <v-card-actions>
            <v-text-field
              v-model="newMessage"
              @keyup.enter="sendMessage"
              placeholder="Escribe tu pregunta..."
              outlined
              dense
              append-icon="mdi-send"
              @click:append="sendMessage"
            ></v-text-field>
          </v-card-actions>
        </v-card>
      </div>
    </v-container>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        signos: [
          { nombre: "Aries", imagen: "aries.png", fechas: "21 Mar - 19 Abr" },
          { nombre: "Tauro", imagen: "tauro.png", fechas: "20 Abr - 20 May" },
          { nombre: "Géminis", imagen: "géminis.png", fechas: "21 May - 20 Jun" },
          { nombre: "Cáncer", imagen: "cáncer.png", fechas: "21 Jun - 22 Jul" },
          { nombre: "Leo", imagen: "leo.png", fechas: "23 Jul - 22 Ago" },
          { nombre: "Virgo", imagen: "virgo.png", fechas: "23 Ago - 22 Sep" },
          { nombre: "Libra", imagen: "libra.png", fechas: "23 Sep - 22 Oct" },
          { nombre: "Escorpio", imagen: "escorpio.png", fechas: "23 Oct - 21 Nov" },
          { nombre: "Sagitario", imagen: "sagitario.png", fechas: "22 Nov - 21 Dic" },
          { nombre: "Capricornio", imagen: "capricornio.png", fechas: "22 Dic - 19 Ene" },
          { nombre: "Acuario", imagen: "acuario.png", fechas: "20 Ene - 18 Feb" },
          { nombre: "Piscis", imagen: "piscis.png", fechas: "19 Feb - 20 Mar" },
        ],
        fechaHoy: new Date().toLocaleDateString("es-ES", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        }),
        messages: [],
        newMessage: "",
        loading: false,
      };
    },
    methods: {
      verHoroscopo(signo) {
        this.$router.push({ name: "horoscopoDetalle", params: { signo } });
      },
      sendMessage() {
        const userMessage = this.newMessage.trim();
        if (!userMessage) return;
  
        this.messages.push({ text: userMessage, isUser: true });
        this.newMessage = "";
        this.loading = true;
  
        const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/chat_oraculo.php`;
  
        axios
          .post(apiUrl, {
            message: userMessage,
          })
          .then((response) => {
            this.messages.push({ text: response.data.reply, isUser: false });
          })
          .catch((error) => {
            console.error("Error al enviar el mensaje: ", error);
            this.messages.push({
              text: "No se pudo conectar con el Oráculo.",
              isUser: false,
            });
          })
          .finally(() => {
            this.loading = false;
          });
      },
    },
  };
  </script>
  
  <style scoped>
  h1 {
    color: #ff9f1a;
    text-align: center;
    font-size: 18px;
    margin-bottom: 8px;
  }
  
  h2 {
    color: #f7f1e3;
    text-align: center;
    font-size: 14px;
    margin-bottom: 20px;
  }
  
  .v-container {
    padding: 10px;
  }
  
  .v-card {
    transition: box-shadow 0.3s;
    cursor: pointer;
    border-radius: 10px;

  }
  
  .v-card:hover {
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
  }
  
  .v-card-title {
    text-align: center;
    font-weight: bold;
    font-size: 15px;
    color: #ff9f1a;
    margin: 0;
  }
  
  .v-card-subtitle {
    text-align: center;
    font-size: 8px;
    color: #f7f1e3;
    margin-top: -8px;
  }
  
  .chat-container {
    margin-top: 20px;
  }
  
  .messages {
    max-height: 300px;
    overflow-y: auto;
    padding: 10px;
  }
  
  .message {
    margin-bottom: 10px;
  }
  
  .is-user {
    text-align: right;
  }
  
  .chip {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 15px;
    background-color: #e0e0e0; /* Mensajes del Oráculo */
    color: black;
    margin: 2px 0;
  }
  
  .message.is-user .chip {
    background-color: #dcf8c6; /* Verde claro para mensajes del usuario, similar a WhatsApp */
    color: black;
  }
  </style>
  