<template>
    <v-container class="horoscope-container">
      <v-row>
        <v-col cols="12" class="text-center">
          <v-img
            :src="require(`@/assets/signos/${signo.toLowerCase()}.png`)"
            height="120px"
            contain
            class="signo-img"
          ></v-img>
          <h1>{{ signo }}</h1>
          <p class="fecha">{{ fechaHoy }}</p>
        </v-col>
      </v-row>
      <v-row class="horoscope-content" v-if="horoscopo">
        <v-col
          v-for="(contenido, categoria) in mostrarHoroscopo"
          :key="categoria"
          cols="12"
          md="6"
        >
          <div class="categoria">
            <font-awesome-icon
              :icon="getIcon(categoria)"
              class="categoria-icon"
            />
            <div class="categoria-content">
              <h3 class="categoria-title">{{ formatearCategoria(categoria) }}</h3>
              <p class="categoria-text">{{ contenido }}</p>
            </div>
          </div>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn
            icon
            @click="shareOnTwitter"
            title="Compartir en Twitter"
          >
            <font-awesome-icon :icon="['fab', 'twitter']" />
          </v-btn>
          <v-btn
            icon
            @click="shareOnFacebook"
            title="Compartir en Facebook"
          >
            <font-awesome-icon :icon="['fab', 'facebook']" />
          </v-btn>
          <v-btn
            icon
            @click="shareOnWhatsApp"
            title="Compartir en WhatsApp"
          >
            <font-awesome-icon :icon="['fab', 'whatsapp']" />
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <p>Cargando horóscopo...</p>
        </v-col>
      </v-row>
      <!-- Nueva sección para "Conversa con el Oráculo" -->
      <v-row class="chat-oraculo">
        <v-col cols="12" class="text-center">
          <v-btn
            icon
            large
            @click="conversaConOraculo"
            title="Conversa con el Oráculo"
          >
            <font-awesome-icon :icon="['fas', 'comments']" />
          </v-btn>
          <p>Conversa con el Oráculo</p>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        horoscopo: null,
        signo: this.$route.params.signo,
        fechaHoy: new Date().toLocaleDateString('es-ES', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }),
      };
    },
    computed: {
      mostrarHoroscopo() {
        if (!this.horoscopo) return {};
        const { id, signo, fecha, ...detalles } = this.horoscopo; // eslint-disable-line no-unused-vars
        return detalles;
      },
    },
    mounted() {
      this.obtenerHoroscopo();
    },
    methods: {
      async obtenerHoroscopo() {
        const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/get_horoscope.php?signo=${this.signo}`;
        try {
          const response = await axios.get(apiUrl);
          if (response.data.error) {
            throw new Error(response.data.error);
          }
          this.horoscopo = response.data;
          console.log('Horóscopo:', this.horoscopo);
        } catch (error) {
          console.error('Error al obtener el horóscopo:', error);
        }
      },
      getIcon(categoria) {
        const iconMap = {
          Salud: ['fas', 'heartbeat'],
          'Amor y Relaciones': ['fas', 'heart'],
          'Dinero y Finanzas': ['fas', 'dollar-sign'],
          'Carrera y Trabajo': ['fas', 'briefcase'],
          'Crecimiento Personal': ['fas', 'leaf'],
          Educación: ['fas', 'graduation-cap'],
          Viajes: ['fas', 'plane'],
          'Creatividad y Proyectos': ['fas', 'palette'],
          'Bienestar Emocional': ['fas', 'smile'],
          'Hogar y Familia': ['fas', 'home'],
        };
        const formattedCategory = this.formatearCategoria(categoria);
        return iconMap[formattedCategory] || ['fas', 'question-circle'];
      },
      formatearCategoria(categoria) {
        const formatMap = {
          salud: 'Salud',
          amor_relaciones: 'Amor y Relaciones',
          dinero_finanzas: 'Dinero y Finanzas',
          carrera_trabajo: 'Carrera y Trabajo',
          crecimiento_personal: 'Crecimiento Personal',
          educacion: 'Educación',
          viajes: 'Viajes',
          creatividad_proyectos: 'Creatividad y Proyectos',
          bienestar_emocional: 'Bienestar Emocional',
          hogar_familia: 'Hogar y Familia',
        };
        return formatMap[categoria] || categoria;
      },
      shareOnTwitter() {
        const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
          `Horóscopo de ${this.signo} para hoy (${this.fechaHoy}): ${window.location.href}`
        )}`;
        window.open(url, '_blank');
      },
      shareOnFacebook() {
        const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          window.location.href
        )}&quote=${encodeURIComponent(`Horóscopo de ${this.signo} para hoy (${this.fechaHoy})`)}`;
        window.open(url, '_blank');
      },
      shareOnWhatsApp() {
        const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          `Horóscopo de ${this.signo} para hoy (${this.fechaHoy}): ${window.location.href}`
        )}`;
        window.open(url, '_blank');
      },
      conversaConOraculo() {
        this.$router.push({ name: 'TableroZodiacal' });
      },
    },
  };
  </script>
  
  <style scoped>
  .horoscope-container {
    background-color: #1e1e2f;
    padding: 20px;
    border-radius: 10px;
    color: #f7f1e3;
  }
  
  .signo-img {
    margin-bottom: 10px;
    border-radius: 50%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
  
  h1 {
    font-size: 24px;
    font-weight: bold;
    color: #ff9f1a;
    margin-bottom: 5px;
  }
  
  .fecha {
    font-size: 14px;
    color: #ccc;
  }
  
  .horoscope-content {
    margin-top: 15px;
  }
  
  .categoria {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 10px;
    border-radius: 8px;
    transition: background-color 0.3s;
  }
  
  .categoria:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .categoria-icon {
    font-size: 28px;
    color: #ff9f1a;
    margin-right: 10px;
  }
  
  .categoria-content {
    flex: 1;
  }
  
  .categoria-title {
    font-size: 18px;
    font-weight: bold;
    color: #ff9f1a;
    margin-bottom: 5px;
  }
  
  .categoria-text {
    font-size: 14px;
    color: #f7f1e3;
  }
  
  .v-btn {
    margin: 5px;
    color: #fff;
    background-color: #40407a;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
  }
  
  .v-btn:hover {
    background-color: #ff9f1a;
  }
  
  .chat-oraculo {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #ccc;
  }
  
  .chat-oraculo p {
    font-size: 16px;
    color: #f7f1e3;
    margin-top: 10px;
  }
  </style>
  