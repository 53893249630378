import { createRouter, createWebHistory } from 'vue-router';
import TableroZodiacal from '../components/TableroZodiacal.vue';
import HoroscopoDetalle from '../components/HoroscopoDetalle.vue';

const routes = [
  {
    path: '/',
    name: 'TableroZodiacal',
    component: TableroZodiacal,
  },
  {
    path: '/horoscopo/:signo',
    name: 'horoscopoDetalle',
    component: HoroscopoDetalle,
  },
  {
    path: '/tablero-zodiacal', // Ensuring this path also routes to TableroZodiacal
    name: 'tableroZodiacal',
    component: TableroZodiacal,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
