import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createVuetify } from 'vuetify';
import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css'; // Importa los iconos de Material Design

import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons'; // Importar iconos de marcas

// Añade los iconos a la librería
library.add(fas, fab);

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'esotericTheme',
    themes: {
      esotericTheme: {
        dark: true,
        colors: {
          background: '#2c2c54',
          surface: '#40407a',
          primary: '#ff9f1a',
          secondary: '#f7f1e3',
          accent: '#ff3f34',
          error: '#ff5252',
          info: '#2196f3',
          success: '#4caf50',
          warning: '#fb8c00',
        },
      },
    },
  },
});

createApp(App)
  .use(router)
  .use(vuetify)
  .component('font-awesome-icon', FontAwesomeIcon) // Registrar el componente
  .mount('#app');
