<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<style>
@import '~vuetify/styles'; /* Importa los estilos de Vuetify */
.esoterico {
  font-family: 'Arial', sans-serif;
  min-height: 100vh;
  padding: 20px;
  text-align: center;
}
</style>
